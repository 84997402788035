import http from '@/shared/http'
import apiResultWrapper from '@/shared/apiResultWrapper'

// fetch information about blotters dashboard
const fetchBlotterDashboard = async (id) => {
  const date = new Date()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return apiResultWrapper.apiResult(await http.get(`/api/v1/blotters/dashboard/${id}/${year}/${month}`))
}
// fetch information about blotter accounts - in BE is organize structure of users
const fetchAccounts = async () => apiResultWrapper.apiResult(await http.get('/api/v1/blotters/accounts'))

// upload new blotter file
const uploadBlotterFile = async ({ file, userId, accessId, type, date }) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('userId', userId)
  formData.append('accessId', accessId)
  formData.append('type', type)
  formData.append('date', date)
  return apiResultWrapper.apiResult(await http.post('/api/v1/blotters/upload-blotter', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  }))
}

const downloadBlotterFile = async ({ id }) => {
  return await http.get(`/api/v1/blotters/${id}/download`, { responseType: 'blob' })
}

const findBlotterById = async ({ id }) => apiResultWrapper.apiResult(await http.get(`/api/v1/blotters/${id}`))

const saveManagerReview = async ({ id, review }) => apiResultWrapper.apiResult(await http.post(`/api/v1/blotters/${id}/manager-review`, { review: review }))

const saveOwnerReview = async ({ id, review }) => apiResultWrapper.apiResult(await http.post(`/api/v1/blotters/${id}/owner-review`, { review: review }))

const acknowledgeManagerReview = async ({ id, review }) => apiResultWrapper.apiResult(await http.post(`/api/v1/blotters/${id}/acknowledge-manager-review`, { review: review }))

const acknowledgeSeniorManagerReview = async ({ id, review }) => apiResultWrapper.apiResult(await http.post(`/api/v1/blotters/${id}/acknowledge-senior-manager-review`, { review: review }))

export default {
  fetchBlotterDashboard,
  fetchAccounts,
  uploadBlotterFile,
  downloadBlotterFile,
  findBlotterById,
  saveManagerReview,
  saveOwnerReview,
  acknowledgeManagerReview,
  acknowledgeSeniorManagerReview
}
