export default {
  SUPPORT_IT: 'SUPPORT_IT', // indicate that can see all user's IT issues
  SUPPORT_ACCOUNTING: 'SUPPORT_ACCOUNTING', // indicate that can see all user's accountant issues
  SUPPORT_MANAGEMENT: 'SUPPORT_MANAGEMENT', // indicate that can see all user's management issues
  BLOTTER_UPLOAD: 'BLOTTER_UPLOAD',
  BLOTTER_MANAGER: 'BLOTTER_MANAGER',
  BLOTTER_ADMIN: 'BLOTTER_ADMIN',
  MNDB_TOOL_TERMINAL: 'MNDB_TOOL_TERMINAL',
  STERLING_REPORTS: 'STERLING_REPORTS',
  STERLING_REPORTS_ADMIN: 'STERLING_REPORTS_ADMIN',
  EZE_REPORTS: 'EZE_REPORTS',
  EZE_REPORTS_ADMIN: 'EZE_REPORTS_ADMIN',
  MANAGE_SYSTEM_SETTINGS: 'MANAGE_SYSTEM_SETTINGS',
  MONTHLY_STATEMENT: 'MONTHLY_STATEMENT',
  MANAGE_MONTHLY_STATEMENT: 'MANAGE_MONTHLY_STATEMENT',
  SYSTEM_VERSION: 'SYSTEM_VERSION'
}
