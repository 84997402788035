<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    {{isIdChanged}}
    <v-row>
      <v-col cols="12">
        <div
          v-if="!loading"
          style="height: 4px;"
        />
        <v-progress-linear
          v-else
          color="primary"
          indeterminate
        />
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <div
        class="text-center text-uppercase"
        style="width: 100%; letter-spacing: 0.2rem;"
      >
        <span>
          <h2 style="font-weight: 300;">{{ blotter.name }} <span style="font-weight: 400;">({{ blotter.type }})</span></h2>
        </span>
      </div>
    </v-row>
    <v-row>
      <v-col cols="7">
        <div>
          <div
          ref="pdfAppContainer">
            <pdf-app
            ref="pdf-app"
            :theme="$vuetify.theme.dark ? 'dark' : 'light'"
            style="height: 100vh;"
            :pdf="pdfContent"
            :config="config"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="5">
        <v-row class="mt-14">
          <v-col cols="12">
            <span
              style="font-weight: 300; font-size: 1.3rem"
              class="font-italic"
            >Manager Review :</span>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="blotter.reviewByManager"
              :disabled="loading"
              :readonly="!hasPermission(permissions.BLOTTER_MANAGER)"
              filled
              no-resize
              full-width
              rows="12"
              counter="4096"
              maxlength="4096"
            />
          </v-col>
          <v-col
            cols="12"
            class="text-right"
          >
            <v-btn
              color="primary"
              :disabled="loading || userInfo().accessId !== blotter.accessId || blotter.reviewByManager == null || blotter.acknowledgeManagerReview"
              class="white--text btn-center"
              right
              target="_blank"
              @click="acknowledgeManagerReview"
            >
              Acknowledge Comment
            </v-btn>
            <v-btn
              color="primary"
              :disabled="loading || !hasPermission(permissions.BLOTTER_MANAGER)"
              class="white--text btn-center"
              right
              target="_blank"
              @click="saveManagerComment"
            >
              Save Comment
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-16">
          <v-col cols="12">
            <span
              style="font-weight: 300; font-size: 1.3rem"
              class="font-italic"
            >Senior Manager Review :</span>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="blotter.reviewByOwner"
              :disabled="loading"
              :readonly="!hasPermission(permissions.BLOTTER_ADMIN)"
              filled
              no-resize
              full-width
              rows="12"
              counter="4096"
              maxlength="4096"
            />
          </v-col>
          <v-col
            cols="12"
            class="text-right"
          >
            <v-btn
              color="primary"
              :disabled="loading || userInfo().accessId !== blotter.accessId || blotter.reviewByOwner == null || blotter.acknowledgeSeniorManagerReview"
              class="white--text btn-center"
              right
              target="_blank"
              @click="acknowledgeSeniorManagerReview"
            >
              Acknowledge Comment
            </v-btn>
            <v-btn
              color="primary"
              :disabled="loading || !hasPermission(permissions.BLOTTER_ADMIN)"
              class="white--text btn-center"
              right
              target="_blank"
              @click="saveOwnerComment"
            >
              Save Comment
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <div class="text-center">
        <v-dialog
          v-model="dialog.show"
          width="500"
        >
          <v-card>
            <v-card-title class="headline lighten-2">
              <span
                class="red--text text-uppercase"
                style="letter-spacing: 0.3rem;"
              > {{ dialog.title }} </span>
            </v-card-title>

            <v-card-text
              align="center"
            >
              {{ dialog.message }}
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="dialog = false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import blotterService from '@/service/BlotterService'
import { mapGetters } from 'vuex'
import permissions from '@/shared/permissions'
import 'vue-pdf-app/dist/icons/main.css'

export default {
  name: 'BlotterDetails',
  components: {
    PdfApp: () => import('vue-pdf-app')
  },
  data: () => ({
    scale: 2,
    config: {
      toolbar: {
        toolbarViewerLeft: { findbar: false }
      }
    },
    permissions: permissions,
    loading: false,
    pdfContent: null,
    show: true,
    blotter: {
      name: '',
      type: '',
      reviewByOwner: '',
      reviewByManager: ''
    },
    dialog: {
      show: false,
      message: '',
      title: ''
    },
    currentBlotterId: -1,
    isFullscreenBrowser: false
  }),
  beforeMount () {
    this.loadData()
  },
  computed: {
    ...mapGetters(['hasRoles', 'hasPermission', 'userInfo']),
    isIdChanged () {
      if (this.currentBlotterId !== this.$route.params.id) this.loadData()
      return ''
    }
  },
  methods: {
    async loadData () {
      this.loading = true
      await this.downloadBlotter()
      await this.fetchBlotter()
      this.loading = false
    },
    async fetchBlotter () {
      const id = this.$route.params.id
      this.currentBlotterId = this.$route.params.id
      const response = await blotterService.findBlotterById({ id })
      if (response.status === 200) {
        this.blotter = response.data
      }
    },
    async downloadBlotter () {
      const id = this.$route.params.id
      const response = await blotterService.downloadBlotterFile({ id })
      this.pdfContent = new Uint8Array((await new Blob([response.data]).arrayBuffer())).buffer
    },
    async saveManagerComment () {
      this.loading = true
      const response = await blotterService.saveManagerReview({
        id: this.blotter.id,
        review: this.blotter.reviewByManager
      })
      console.log(response)
      if (response.status === 200) {
        this.blotter.reviewByOwner = response.data.reviewByOwner
      } else {
        this.dialog = {
          show: true,
          title: 'Error',
          message: response.error.message
        }
      }
      this.loading = false
    },
    async saveOwnerComment () {
      this.loading = true
      const response = await blotterService.saveOwnerReview({ id: this.blotter.id, review: this.blotter.reviewByOwner })
      if (response.status === 200) {
        this.blotter.reviewByManager = response.data.reviewByManager
      } else {
        this.dialog = {
          show: true,
          title: 'Error',
          message: response.error.message
        }
      }
      this.loading = false
    },
    async acknowledgeManagerReview () {
      this.loading = true
      const response = await blotterService.acknowledgeManagerReview({ id: this.blotter.id })
      if (response.status === 200) {
        this.blotter = response.data
      } else {
        this.dialog = {
          show: true,
          title: 'Error',
          message: response.error.message
        }
      }
      this.loading = false
    },
    async acknowledgeSeniorManagerReview () {
      this.loading = true
      const response = await blotterService.acknowledgeSeniorManagerReview({ id: this.blotter.id })
      if (response.status === 200) {
        this.blotter = response.data
      } else {
        this.dialog = {
          show: true,
          title: 'Error',
          message: response.error.message
        }
      }
      this.loading = false
    },
    overridePresentationViewBtnBehaviour() {
      setTimeout(() => {
        const oldPreviewBtn = document.getElementById('presentationMode')
        const newPreviewBtn = oldPreviewBtn.cloneNode(true)
        oldPreviewBtn.parentNode.replaceChild(newPreviewBtn, oldPreviewBtn)
        newPreviewBtn.addEventListener('click', this.onPresentationViewBtnClicked)
      }, 1000)
    },
    onPresentationViewBtnClicked() {
      this.isFullscreenBrowser = !this.isFullscreenBrowser
      const htmlElement = document.getElementsByTagName("html")[0]
      const bodyElement = document.getElementsByTagName("body")[0]
      const btn = document.getElementById('presentationMode')
      const container = this.$refs['pdfAppContainer']
      
      if (this.isFullscreenBrowser) {
        container.classList.add('fullscreen-browser')
        htmlElement.style = 'height:100%; overflow: hidden;'
        bodyElement.style = 'height:100%; overflow: hidden;'
        btn.setAttribute('title', 'Exit Presentation Mode')
      }
      else {
        container.classList.remove('fullscreen-browser')
        htmlElement.style = ''
        bodyElement.style = ''
        btn.setAttribute('title', 'Switch to Presentation Mode')
      }
    }
  },
  mounted() {
    this.overridePresentationViewBtnBehaviour()
  }
}
</script>
<style>
.fullscreen-browser {
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #bebebe;
}
</style>